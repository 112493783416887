import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Masonry from 'react-masonry-css'
import { HTMLContent } from "../components/Content";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

// eslint-disable-next-line
export const WorkPostTemplate = ({
  title,
  helmet,
  photos,
  description
}) => {
  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1
  };
  return (
    <section className="section works">
      <Helmet title={`Itza | ${title}`} />
        <div className="columns">
          <div className="column"></div>
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column">
              {photos.map((photo, index) => (
                <PreviewCompatibleImage key={index} imageInfo={photo} />
              ))}
            </Masonry>
            {/* {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                <ul className="taglist">
                  {tags.map((tag) => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null} */}
      </div>
      <div className="body">
        <p className="title-size">
          {title}
        </p>
        <HTMLContent className="des" content={description}/>
      </div>
    </section>
  );
};

WorkPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  photos: PropTypes.array,
};

const WorkPost = ({data}) => {
const { markdownRemark: post } = data;

  return ( 
    <Layout>
      <WorkPostTemplate
        photos={post.frontmatter.Photoshoot.photos}
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      /> 
    </Layout>
  );
};

WorkPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default WorkPost;

export const pageQuery = graphql`
query WorkPostByID($id: String!) {
  markdownRemark(id: { eq: $id }) {
    id
    html
    frontmatter {
      title
      description
      tags
      Photoshoot {
        photos {
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
}
`;
